<template>
  <div>
    <v-data-table :headers="headers" :items="actions" class="elevation-1">
      <template v-slot:item.isOnCooldown="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="item.isOnCooldown ? 'warning' : 'success'"
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ item.isOnCooldown ? "mdi-sort-clock-ascending" : "mdi-check" }}
            </v-icon>
          </template>
          <span>{{
            item.isOnCooldown
              ? $t("actions.onCooldown")
              : $t("actions.noCooldownActive")
          }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ humanDate(item.updatedAt) }}
      </template>
      <template v-slot:item.cooldownUntil="{ item }">
        {{ humanDate(item.cooldownUntil) }}
      </template>

      <template v-slot:item.eventType="{ item }">
        <v-chip>{{ eventTypeName(item.eventType) }}</v-chip>
      </template>

      <template v-slot:item.cooldownOnAction="{ item }">
        <v-icon :color="item.cooldownOnAction ? 'success' : 'error'">{{
          item.cooldownOnAction ? "mdi-check" : "mdi-window-close"
        }}</v-icon>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon @click="editAction(item)" class="mr-2" color="primary">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteAction(item)" color="error">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Action",

  data() {
    return {
      headers: [
        { text: this.$t("actions.fields.isOnCooldown"), value: "isOnCooldown" },
        { text: this.$t("actions.fields.title"), value: "title" },
        { text: this.$t("actions.fields.description"), value: "description" },
        { text: this.$t("actions.fields.eventType"), value: "eventType" },
        { text: this.$t("actions.fields.duration"), value: "duration" },
        // {
        //   text: this.$t("actions.fields.cooldownOnAction"),
        //   value: "cooldownOnAction",
        // },
        // {
        //   text: this.$t("actions.fields.cooldownMinutes"),
        //   value: "cooldownMinutes",
        // },
        // {
        //   text: this.$t("actions.fields.cooldownUntil"),
        //   value: "cooldownUntil",
        // },
        { text: this.$t("common.createdAt"), value: "createdAt" },
        { text: this.$t("common.updatedAt"), value: "updatedAt" },
        { text: "", value: "action", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState("actions", ["actions"]),

    eventTypeName() {
      return (eventType) => {
        return this.$t(`actions.eventTypes.${eventType}`);
      };
    },
  },

  methods: {
    ...mapActions("actions", ["getActions"]),

    editAction(item) {
      this.$router.push(`/actions/${item.actionId}`);
    },

    async deleteAction(item) {
      this.$store.dispatch("actions/deleteAction", item.actionId);
      await this.getActions();
    },
  },

  async created() {
    await this.getActions();
  },
};
</script>
